export default defineNuxtRouteMiddleware((to, from) => {
  const config = useRuntimeConfig()
  const token = useCookie('token', { path: '/', domain: config.public.COOKIE_DOMAIN })
  const redirect = useCookie('redirect', { path: '/', maxAge: 3600, domain: config.public.COOKIE_DOMAIN })
  const nuxt = useNuxtApp()

  if (!token.value) {
    redirect.value = to.fullPath
    return navigateTo(nuxt.$localePath('/continue'))
  }
})
